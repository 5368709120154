.zoom-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 8px;
  right: 8px;
}

.map-type-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 8px;
  right: 8px;
}

.no-content-text {
  font-family: Open-Sans, sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #6f6f6f;
}

.image-box-site-netact {
  min-height: 100px;
  width: 100px;
  cursor: pointer;
}

.image-list-label {
  font: normal normal normal 10px/15px Roboto, sans-serif;
  color: #737373;
}

.image-title {
  font: normal normal 600 14px/18px Roboto, sans-serif;
  color: #353435;
}
