.vendor-title {
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 8px;
}
.border-left{
    border-radius: 6px 0 0 6px;
}
.border-right {
    border-radius: 0 6px 6px 0 ;
}
.border-inactive {
    border : 1px solid #D5D6D7 !important;
}
.border-active {
    border : 1px solid #1677FF !important;
    color: #1677FF !important;
}