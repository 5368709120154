.header {
  height: 56px;
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #8f9fac33;
  opacity: 1;
  padding: 0px 16px;
  position: relative;
  z-index: 999;
  gap: 49px;
}

ul.nav {
  width: fit-content;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

ul.nav li {
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  cursor: pointer;
  height: 100%;
  /* text-transform: uppercase; */
  font: normal normal normal 14px/24px Roboto ,sans-serif;
  letter-spacing: 0.28px;
  color: #000000;
  user-select: none;
}
.search-bar{
  position: relative;
  top: 12px;
  height: fit-content !important;
  width: 350px;
}

ul.nav li.active {
  border-bottom: 4px solid #1677FF;
  color: #1677FF;
}

.header-logo {
  height: 34px;
  cursor: pointer;
}
.border-blue{
  border: 1px solid #0958D9 !important;
  border-radius: 6px !important;
}
.border{
  border: 1px solid #D5D6D7 !important;
  border-radius: 6px !important;
  padding : 5px 11px;
}
.border-left-only{
  border: 1px solid #D5D6D7 !important;
  border-radius: 6px 0 0 6px!important;
  padding : 5px 11px;
}
.border-right{
  border: 1px solid #D5D6D7 !important;
  border-radius: 0 6px 6px 0 !important;
  padding : 5px 11px;
}
.border-noradius{
  border: 1px solid #D5D6D7 !important;
  border-radius: 0 !important;
  padding : 5px 11px;
}
.border-nopadding{
  border: 1px solid #D5D6D7 !important;
  border-radius: 6px !important;
}
.technology-item{
  margin-left: 10px;
  color:#0958D9;
  font-size: 14px;
  font-weight: 600;
}
.tech-text{
  color: #000000A6;
}
.background-secondary {
  background-color: #F5F5F5;
}
.height-search {
  height: 32px !important;
}
.height-search-text{
  height: 20px !important;
  margin-left: 10px !important;
}
.icon-bell{
  padding: 8px;
}
.bg-white{
  background-color: #ffff ;
}