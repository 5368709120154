/* Remove browser defaults */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
  -webkit-user-drag: none;
  font-family: 'Roboto', sans-serif;
  word-wrap: break-word;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  background-color: #f3f3f5;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #0059b270;
}

a {
  color: transparent;
  text-decoration: none;
}

.dropstart a {
  outline: none;
}

p {
  margin-bottom: 0px;
}

/* input:focus {
  outline: 0.5px solid #0059b2 !important;
} */

input:disabled {
  background: #f2f2f5 0% 0% no-repeat padding-box;
}

/* textarea:focus {
  outline: 0.5px solid #0059b2 !important;
} */

textarea:disabled {
  background: #f2f2f5 0% 0% no-repeat padding-box;
}

/* select:focus {
  outline: 0.5px solid #0059b2 !important;
} */

select:disabled {
  background: #f2f2f5 0% 0% no-repeat padding-box;
}

span {
  user-select: text;
}

.cursor-pointer {
  cursor: pointer !important;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 0px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 0px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.network-activity-modal {
  --bs-modal-width: 60%;
}

.action-modal {
  --bs-modal-width: 450px;
}

.nav-tabs .nav-link {
  border: none;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.28px;
  color: #8f9fac;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  opacity: 1;
  cursor: pointer;
  padding: 1px 8px !important;
  gap:8px;
}

.nav-tabs .nav-link.active {
  color: #0059b2;
  font-weight: 700;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aptab {
  justify-content: center;
}

.aptab .nav-link {
  font-size: 14px;
  font-weight: 500;
}

.aptab .nav-item.show .nav-link,
.aptab .nav-link.active {
  color: #0059b2;
  border-color: transparent;
  background: #0059b219 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  font-weight: 500;
}

.font-14 {
  font-size: 14px !important;
  font-weight: 600;
}

.font-20 {
  font-size: 20px !important;
}

.bold {
  font-weight: 700 !important;
}

.row-wise {
  display: flex;
  flex-direction: row;
}
.wrap-flex {
  flex-wrap: wrap;
}

.col-wise {
  display: flex;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.map-center {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.vertical-center {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}
.flex-begin {
  display: flex;
  justify-content: flex-start !important;
}

.app-card {
  display: flex;
  flex-direction: column;
  /* padding: 16px; */
  background: #F5F5F5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  /* border-radius: 12px; */
  opacity: 1;
}
.app-card-white {
  display: flex;
  flex-direction: column;
  /* padding: 16px; */
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  /* border-radius: 12px; */
  opacity: 1;
  border-radius: 6px;
}

.app-card-size {
  width: 350px;
  height: 100% ;
  z-index: 10;
  font-size: 0.875rem;
}

.measure-app-card-size {
  width: 350px;
  margin: 1rem;
}

.measure-info {
  font: normal normal 400 14px/20px Roboto, sans-serif !important;
  letter-spacing: 0px;
  color: #475057;
}

.measure-info-active {
  font: normal normal 300 14px/20px Roboto, sans-serif !important;
  letter-spacing: 0px;
  color: #f59343;
}

.elevation-profile-chart {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.plain-card {
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001c;
  width: 350px;
  height: 100%;
  opacity: 1;
}
.plain-card-search {
  
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001c;
  width: 350px;
  height: 100%;
  opacity: 1;
}

.page-heading {
  font: normal normal 500 20px/22px Roboto, sans-serif !important;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
  margin-bottom: 27px;
}

.legend-title {
  font: normal normal 600 14px/24px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
}

.legend-details {
  font: normal normal 400 14px/24px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #475057;
  opacity: 1;
}

.app-card-heading {
  font: normal normal 400 14px/22px Roboto, sans-serif !important;
  letter-spacing: 0.28px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

.sub-heading {
  font: normal normal 500 12px/22px Roboto, sans-serif !important;
  letter-spacing: 0.24px;
  color: #aaaaaa;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 10px;
}

.app-card-action-heading {
  font: normal normal 500 14px/16px Roboto, sans-serif !important;
  letter-spacing: 0.28px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

.input-box,
.input-box:focus,
.input-with-icon {
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
  box-shadow: none;
  height: 32px !important;
  padding: 5px 12px ;
}

.input-password-box,
.input-password-box:focus {
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 12px;
  opacity: 1;
  box-shadow: none;
  height: 46px !important;
}
.background-white {
  background: #fff  
}

.textbox-box,
.textbox-box:focus {
  background: #fff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
  box-shadow: none;
}

.input-text {
  font: normal normal 400 14px/16px Roboto, sans-serif !important;
  color: #353435;
  opacity: 1;
}

.input-label {
  font: normal normal 400 14px/16px Roboto, sans-serif !important;
  color: #9b9b9b !important;
  opacity: 1;
}

.primary-action-button {
  background: #0059b2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  font: normal normal normal 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  border: none;
}

.primary-action-button:disabled {
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  font: normal normal normal 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  border: none;
}

.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: #f0f1f3 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  cursor: pointer;
}

.icon-background-active {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: #e5eef7 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  cursor: pointer;
}
.icon-size-header {
font-size: 16px;
margin-right: 8px;
}

.icon {
  color: #8f9fac;
  opacity: 1;
}

.icon-active {
  color: #0059b2;
  opacity: 1;
}

.map-icon {
  color: #000000;
  font-weight: 400;
  opacity: 1;
}

.map-icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #0000001a;
  opacity: 1;
}

.backdrop {
  background: #f9fafc 0% 0% no-repeat padding-box;
  opacity: 1;
  height: calc(100vh - 58px);
  width: 100%;
  display: flex;
  position: absolute;
  padding: 20px;
}

.add-button,
.edit-button {
  display: flex;
  align-items: center;
  border: 1px solid #0059b2;
  background: #fff;
  border-radius: 40px;
  height: fit-content;
  width: fit-content;
  padding: 0px 6px;
  justify-content: center;
  cursor: pointer;
  font: normal normal 600 12px/18px Roboto, sans-serif !important;
  letter-spacing: 0.24px;
  color: #0059b2;
  text-transform: uppercase;
  opacity: 1;
}

.add-button:hover,
.edit-button:hover {
  background: #0059b2;
  color: #fff;
}

.delete-button {
  display: flex;
  align-items: center;
  border: 1px solid #e22b2b;
  border-radius: 40px;
  height: fit-content;
  width: fit-content;
  padding: 0px 6px;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  font: normal normal 500 10px/20px Roboto, sans-serif !important;
  letter-spacing: 0.24px;
  color: #e22b2b;
  text-transform: uppercase;
  opacity: 1;
}

.delete-button:hover {
  background: #e22b2b;
  color: #fff;
}

.table-header-card {
  background: #00000005 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  height: 48px;
  font: normal normal 400 12px/22px Roboto, sans-serif;
  letter-spacing: 0.24px;
  color: #475057;
  margin: 0;
}
.border-left-line{
  border-left: 1px solid #E3E4E4;
}
.font-bold{
  font-weight: bold;
}
.empty-data-label {
  font: normal normal 400 12px/22px Roboto, sans-serif;
  letter-spacing: 0.24px;
  color: #475057;
}

.modal-title {
  font: normal normal 500 14px/22px Roboto, sans-serif;
  letter-spacing: 0.28px;
  color: #000000;
  text-transform: uppercase;
}

.azimuth-icon-position {
  top: calc(50% + 22px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox-label {
  font: normal normal 400 14px/24px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.labelless-input {
  background: #ffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #cccccc;
  border-radius: 6px;
  opacity: 1;
  /* height: 48px; */
  padding: 6px 12px;
  appearance: none;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  /* margin-right: 10px; */
}
.primary-blue{
  color: #0958D9;
}
.info-icon-blue{
  color: #1677FF !important;
}
.blue-bg{
  background-color: #1677FF;
}
.spinner-body {
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

.spinner {
  height: 40px;
  width: 40px;
  -webkit-animation: spinner-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: spinner-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes spinner-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;

  margin: auto;
  width: 8px;
  height: 8px;
  background: #0059b2;
  border-radius: 50%;
  -webkit-animation: spinner-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: spinner-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes spinner-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(40px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes spinner-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(40px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.spinner::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #0059b2;
  border-radius: 50%;
  -webkit-animation: spinner-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: spinner-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes spinner-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(-40px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes spinner-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-40px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.spinner span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 40px;
  width: 40px;
}
.spinner span::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #0059b2;
  border-radius: 50%;
  -webkit-animation: spinner-4 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: spinner-4 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes spinner-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, 40px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes spinner-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 40px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.spinner span::after {
  content: '';
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #0059b2;
  border-radius: 50%;
  -webkit-animation: spinner-5 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: spinner-5 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes spinner-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, -40px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes spinner-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -40px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.dropzone-set {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.dropzone-uploaded {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0px 12px;
  border-width: 2px;
  border-radius: 12px;
  border-color: #00d56e;
  border-style: dashed;
  background-color: #fff;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #00d56e;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 48px;
  margin-right: 10px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0px 12px;
  border-width: 2px;
  border-radius: 6px;
  border-color: #cccccc;
  border-style: dashed;
  background-color: #fff;
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #0000008a;
  outline: none;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  cursor: pointer;
  height: 48px;
  margin-right: 10px;
}

.dropzone:focus {
  border-color: #0059b25e;
  color: #0059b2;
  background-color: #e5eef7;
}

.lh-30 {
  line-height: 30px !important;
}
.h-btn{
  height: 32px !important;
}
.f-14{
  font-size: 0.875rem !important;
}
.asset-search {
  width: 27%;
}

.image-boxes {
  background: #fff 0% 0% no-repeat padding-box;
  color: #353435;
  border: 1px solid #0059b21a;
  box-shadow: 0px 3px 8px #00000029;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-boxes:hover {
  background: #0059b21a 0% 0% no-repeat padding-box;
}

.download-label {
  font: normal normal 500 10px/10px Roboto, sans-serif;
}

.download-icon {
  font-size: 14px;
}

.download-box {
  display: flex;
  align-items: center;
  background: #0059b21a 0% 0% no-repeat padding-box;
  border-radius: 12px;
  cursor: pointer;
  color: #0059b2;
  padding: 5px 10px;
}

.network-activity-asset-row {
  cursor: pointer;
}

.sector-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 500 14px/24px Roboto, sans-serif;
  letter-spacing: 0.28px;
  color: #aaaaaa;
  text-transform: uppercase;
  padding: 3px;
  border-bottom: 3px solid #ededf0;
}

.sector-active {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 500 14px/24px Roboto, sans-serif;
  letter-spacing: 0.28px;
  color: #0059b2;
  text-transform: uppercase;
  padding: 3px;

  border-bottom: 3px solid #0059b2;
}

.sector-border {
  border-right: 2px dashed #c1c8ce80;
}

.net-act-details-box {
  border: 1px solid #e3e3e3;
  padding: 12px;
  max-height: 55vh;
  overflow: hidden auto;
  border-radius: 8px;
}

.net-act-bold {
  color: #3c4043;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
  font-size: 15px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}


.net-act-label {
  color: #3c4043;
  text-align: left;
  letter-spacing: 0;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.net-act-title {
  font-size: 18px;
  letter-spacing: 0.1px;
  text-align: left;
  line-height: 22px;
  font-family: 'Google Sans', Arial, sans-serif;
  font-weight: 600;
  color: #3c4043;
}

.rma-history-button {
  padding: 2px 10px;
  margin-left: 20px;
  background-color: #0059b2;
  border-radius: 50px;
  color: white;
  cursor: pointer;
}

.rma-history-button:hover {
  background-color: white;
  outline: 2px solid #0059b2;
  color: #0059b2;
}

.rma-history-label {
  font: normal normal 600 12px/24px Roboto, sans-serif;
  letter-spacing: 0.28px;
  text-transform: uppercase;
}

.audit-log-button {
  padding: 2px 10px;
  margin-left: 20px;
  background-color: #0059b2;
  border-radius: 50px;
  color: white;
  cursor: pointer;
}

.audit-log-button:hover {
  background-color: white;
  outline: 2px solid #0059b2;
  color: #0059b2;
}

.audit-log-label {
  font: normal normal 600 12px/24px Roboto, sans-serif;
  letter-spacing: 0.28px;
  text-transform: uppercase;
}
.right {
  margin-left: auto !important;
}
.text-color {
  color: #000000 !important;
}
.text-color-secondary{
  color: #00000073;
}
.serach-icon {
  margin: 0 16px;
}
.gap-8{
  gap: 8px;
}
.divider-vertical {
  height: 16px;
  border: 1px solid #0000000F;
  margin: 0 8px;
}
.divider-horizontal {
  height: 0px;
  border-top: 1px solid #0000000F;
  width: 100%;
  margin-bottom: 4px;
  padding: 0 2px;
}
.f-16{
  font-size: 16px;
}
.bg-sec {
  background-color: #F5F5F5 !important;
}

/* For responsiveness across screens */
html {
  font-size: 100%;
  @media (max-width: 289px) {
    font-size: 45%;
  }
  @media (min-width: 290px) {
    font-size: 50%;
  }
  @media (min-width: 300px) {
    font-size: 52.5%;
  }
  @media (min-width: 320px) {
    font-size: 55.5%;
  }
  @media (min-width: 325px) {
    font-size: 56.5%;
  }
  @media (min-width: 326px) {
    font-size: 57%;
  }
  @media (min-width: 330px) {
    font-size: 59%;
  }
  @media (min-width: 360px) {
    font-size: 62.5%;
  }
  @media (min-width: 411px) {
    font-size: 70%;
  }
  @media (min-width: 500px) {
    font-size: 85%;
  }
  @media (min-width: 720px) {
    font-size: 100%;
  }
  @media (min-width: 1000px) {
    font-size: 110%;
  }
}
html.ios {
  @media (max-width: 359px) and (min-width: 320px) {
    font-size: 59%;
  }
}
