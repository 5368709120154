.accordion-customs {
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-btn-focus-border-color: #8f9fac;
  --bs-accordion-border-color: #dee2e6;
  --bs-accordion-border-width: 0.5px;
  --bs-accordion-border-radius: 6px;
  --bs-accordion-inner-border-radius: 6px;
  --bs-accordion-active-color: #353435;
  --bs-accordion-active-bg: #eef3f7;
  --bs-accordion-body-padding-x: 0px;
  --bs-accordion-body-padding-y: 0px;
  --bs-accordion-bg : #f0f0f0;
  --bs-accordion-btn-padding-x : 12px;
  --bs-accordion-btn-padding-y : 12px;
  --bs-accordion-btn-icon-width :14px
}

.accordion-headline {
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #353435;
  opacity: 1;
}

.submit-parameter-info {
  font: normal normal 400 14px/16px Roboto, sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
  opacity: 1;
}

.status-circle-rma-requested {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #be34fe;
  margin-right: 8px;
}

.status-circle-sas {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #00d56e;
  margin-right: 8px;
}

.status-circle-cloud {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff9445;
  margin-right: 8px;
}

.status-circle-field-tech {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #3474fe;
  margin-right: 8px;
}

.status-circle-decomm-requested {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffde01;
  margin-right: 8px;
}

.status-circle-physically-decomm {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #ff0500;
  background: #f5c0b9;
  margin-right: 8px;
}

.status-circle-decomm {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff0500;
  margin-right: 8px;
}

.status-circle-preloaded {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgb(137, 137, 137);
  margin-right: 8px;
}
.bg-card{
  background-color: #f5f5f5;
}
