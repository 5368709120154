.preloaded-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(137, 137, 137);
}

.field-tech-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(52, 116, 254);
}

.cloud-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(255, 148, 69);
}

.sas-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(0, 213, 110);
}

.rma-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(190, 52, 254);
}

.decom-req-box{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color:  #ffde01;
}
.phy-decom-box {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  border: 2px solid #FF0500; 
  background-color: #f5c0b9;
}

.decom-box{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #FF0500;
}
