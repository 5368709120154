.primary-button {
  background: #1677FF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px !important;
  cursor: pointer;
  padding: 0px 24px;
  min-height: 32px !important;
  font: normal normal 400 14px/16px Roboto ,sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.primary-button-disabled {
  background: #ededf0 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px !important;
  cursor: not-allowed;
  padding: 0px 24px;
  min-height: 32px !important;
  font: normal normal 400 14px/16px Roboto ,sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
}
