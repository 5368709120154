.download-box-decom {
    display: flex;
    align-items: center;
    /* background: #0059b21a 0% 0% no-repeat padding-box; */
    border-radius: 12px;
    cursor: pointer;
    color: #0059b2;
    padding: 5px 10px;
  }
  
  .image-box-site-decom {
    min-height: 100px;
    width: 100px;
    cursor: pointer;
  }
  
  .image-list-label-decom {
    font: normal normal normal 12px/16px Roboto, sans-serif;
    color: #737373;
  }
  .download-label-decom {
    font: normal normal 500 13px/13px Roboto, sans-serif;
  }
  
  .custom-hr {
    height: 3px;
    background-color: #737373;
    width: 98%;
  }

  .custom-hr-decom {
    height: 1px;
    background-color: #e5e5e5;
    width:100%;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  
  .decomm-reason-card {
    background: #f9f9f9 ;
    border-radius: 12px;
    opacity: 1;
    height: 100%;
    width: 98%;
    margin: 3px 0px;
    text-wrap: wrap; 
  }
  .decom-label-bold-blue {
    color: #0059b2;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }

  .decom-label-bold {
    color: #3c4043;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }
  .decom-label-small {
    color: #3c4043;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }

  .decom-label {
    color: #3c4043;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
  .decom-label-head {
    color: #3c4043;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }
  .decom-reason-label {
    color: #3c4043;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
  .decom-label-timestamp {
    color: #3c4043;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    font-style: italic; 
  }