.home-action-heading {
  color: #000;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
}
.app-card-main {
  display: flex;
  flex-direction: column;
  padding: 4px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  border-radius: 8px;
  opacity: 1;
  font-size: .875rem;
}
.list-item-venue{
  height: 52px;
  padding: 4px;
}
